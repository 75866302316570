<template>
  <b-card>
    <FormBuilder
      label="Game"
      create
      :fields="fields"
      :loading-submit="$store.state.user.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'platform',
          label: 'Platform',
          required: true,
          type: 'multiple',
          options: [
            { value: 'pc', text: 'PC' },
            { value: 'mobile', text: 'MOBILE' },
            { value: 'console', text: 'CONSOLE' },
          ],
        },
        {
          key: 'image',
          label: 'Image',
          type: 'image',
          required: true,
        },
        {
          key: 'icon',
          label: 'Icon',
          type: 'image',
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
          type: 'number',
        },
        {
          key: 'specialization',
          label: 'Game Specialization',
          type: 'multiple',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('Game/create', {
        ...data,
        roles: ['customer'],
      })
        .then(callback)
    },
  },
}
</script>

  <style scoped>

  </style>
